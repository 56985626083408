<template>
  <easiLayout>
    <div
      class="md:py-10 h-full min-h-screen !bg-white relative flex items-start sm:items-center justify-center"
    >
      <div
        class="w-11/12 sm:w-10/12 mx-auto md:w-10/12 flex flex-col items-center justify-center"
      >
        <!-- ///// Forgot Pin Form Start  ///// -->
        <easiContainer
          bg="bg-white  px-4 sm:px-10 sm:px-16 lg:px-20 xl:px-24"
          :isClose="false"
          @close="close"
          class="w-full flex flex-col justify-between md:h-screen-80 sm:py-10 rounded-80px"
        >
          <div>
            <div class="flex flex-col gap-2 justify-center items-center">
              <!-- <img class="w-16 h-16" src="@/assets/img/signupLogo.svg" alt="" /> -->
              <h3
                v-if="display === 'email' || display === 'otp'"
                class="head-text"
              >
                Reset your passcode
              </h3>
              <span
                v-if="display === 'email' || display === 'otp'"
                class="sub-head-text text-bodyDark"
                >Please enter the email address or phone <br />
                number associated with your account</span
              >
            </div>

            <form
              @submit.prevent=""
              ref="form"
              autocomplete="on"
              class="md:mt-4 mt-7 w-full md:w-1/2 md:mx-auto"
            >
              <easiTextInput
                noBack
                optional
                v-if="display === 'email' || display === 'otp'"
                showSlotButton
                label="Email address or phone number"
                placeholder="Email address or phone number"
                v-model="userInput"
                :loading="sentOtpLoading"
                :error="errorRules.userInput"
                @back="close"
                @next="
                  () => {
                    if (userInput.trim() == '') {
                      errorRules.userInput = 'Please fill in your email';
                      return false;
                    } else {
                      errorRules.userInput = '';
                      sendOTP();
                    }
                  }
                "
              >
              </easiTextInput>

              <!-- // As an Employee -->
              <div
                v-if="display === 'email' || display === 'otp'"
                class="justify-start items-center mt-4 gap-2 flex"
              >
                <input
                  class="my-2"
                  v-model="asEmployee"
                  type="checkbox"
                  name="box"
                  id=""
                />
                <div
                  class="relative flex items-center text-gray-500 cursor-pointer hover:text-gray-600"
                  x-data="{ hover: false }"
                  @mouseenter="showTip = true"
                  @mouseleave="showTip = false"
                >
                  <p>As an employee</p>
                  <div class="relative">
                    <div
                      v-show="showTip"
                      class="absolute bottom-0 inline-block w-64 px-4 py-3 mb-10 -ml-32 text-white bg-primary rounded-lg"
                      style="z-index: 999"
                      x-show="hover"
                      x-transition:enter="transition ease-out duration-300"
                      x-transition:enter-start="opacity-0 transform scale-90"
                      x-transition:enter-end="opacity-100 transform scale-100"
                      x-transition:leave="transition ease-in duration-300"
                      x-transition:leave-start="opacity-100 transform scale-100"
                      x-transition:leave-end="opacity-0 transform scale-90"
                      x-cloak
                    >
                      <span class="inline-block text-xs"
                        >For employees who have been invited as an uploader,
                        auditor or assigned to a role.</span
                      >
                      <span
                        class="absolute bottom-0 right-0 w-5 h-5 -mb-1 transform rotate-45 bg-primary"
                        style="left: 50%"
                      ></span>
                    </div>
                    <svg
                      class="w-4 h-4 ml-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>

              <div
                v-if="display === 'email' || display === 'otp'"
                class="flex mt-12 justify-center"
              >
                <easiButton
                  @click="sendOTP()"
                  type="submit"
                  :loading="loading"
                  size="small"
                  ><span class="w-32">Continue</span></easiButton
                >
              </div>
              <!-- <easiBlinkingTextInput
                v-if="display === 'otp'"
                optional
                noInput
                label="OTP Verification"
                showSlotButton
                @back="display = 'email'"
                :loading="sentOtpLoading"
                @next="handleOtp"
                class="mt-10"
              >
                <div class="">
                  <p class="text-left text-dark-800 mb-6">
                    Please input OTP sent to your email and phone number
                  </p>
                  <span class="w-fit flex justify-around items-center">
                    <easiOtp
                      class="w-full sm:w-auto"
                      size="10"
                      :token_length="6"
                      :max-length="1"
                      @input="checkOtp"
                      :error="errorRules.otpCode"
                    />
                  </span>
                  <div
                    class="text-dark-800 font-bold text-sm text-left flex items-center justify-start"
                  >
                    Did not receive OTP?
                    <easiButton
                      @click="resendOTP"
                      variant="text"
                      color="primary"
                      type="button"
                      :loading="resentOtpLoading"
                      >Resend</easiButton
                    >
                  </div>
                </div>
              </easiBlinkingTextInput> -->

              <easiBlinkingTextInput
                v-if="display === 'passcode'"
                optional
                noInput
                :showSlotButton="false"
                noButton
                :loading="loading"
                @back="display = 'email'"
                @next="submit"
                class="mt-10"
              >
                <div class="text-center">
                  <h3
                    style="
                      font-weight: 700;
                      font-size: 36px;
                      line-height: 48px;
                      text-align: center;
                      color: #292a29;
                    "
                  >
                    Reset Your Passcode
                  </h3>
                  <span
                    style="
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 24px;
                      text-align: center;
                    "
                    class="text-bodyDark text-center"
                    >Set a 6-digit passcode to protect your account.</span
                  >
                  <div class="mt-10 mb-4">
                    <div class="flex items-center mb-2">
                      <span class="text-dark-700 ml-3">Enter Passcode</span>
                      <img
                        v-if="showPin"
                        @click="showPin = !showPin"
                        class="cursor-pointer ml-2"
                        src="../../assets/icons/eye-close.svg"
                      />
                      <img
                        v-else
                        @click="showPin = !showPin"
                        class="cursor-pointer ml-2"
                        src="../../assets/icons/eye-open.svg"
                      />
                    </div>

                    <span class="mb-4 flex justify-center items-center">
                      <easiOtp
                        class=""
                        :type="showPin ? 'password' : 'number'"
                        :token_length="6"
                        size="10"
                        :max-length="1"
                        @input="checkPin"
                        :error="errorRules.pin"
                      />
                    </span>
                  </div>

                  <div class="text-left">
                    <div class="flex items-center mb-2">
                      <span class="capitalize text-dark-700 ml-3"
                        >Confirm passcode</span
                      >

                      <img
                        v-if="showPin1"
                        @click="showPin1 = !showPin1"
                        class="cursor-pointer ml-2"
                        src="../../assets/icons/eye-close.svg"
                      />
                      <img
                        v-else
                        @click="showPin1 = !showPin1"
                        class="cursor-pointer ml-2"
                        src="../../assets/icons/eye-open.svg"
                      />
                    </div>
                    <span class="mb-4 flex justify-center items-center">
                      <easiOtp
                        class=""
                        :type="showPin1 ? 'password' : 'number'"
                        :token_length="6"
                        size="10"
                        :max-length="1"
                        :error="errorRules.confirmPin"
                        @input="checkConfirmPin"
                      />
                    </span>
                  </div>
                  <div class="flex justify-center mt-8">
                    <easiButton
                      :loading="loading"
                      :disabled="disabled"
                      size="small"
                      rounded="3xl"
                      @click="submit"
                      type="button"
                    >
                      <div class="flex items-center justify-center w-32">
                        Reset Passcode
                      </div>
                    </easiButton>
                  </div>
                </div>
              </easiBlinkingTextInput>
            </form>
          </div>

          <div class="text-dark-800 mt-10 text-base font-medium text-center">
            Back to
            <router-link class="text-secondary" to="/login"
              >Sign in</router-link
            >
          </div>
        </easiContainer>
        <!-- ///// Forgot Pin Form End  ///// -->

        <!-- ///// Success Message Start ///// -->
        <easiContainer
          :isClose="false"
          v-if="display === 'success'"
          class="py-8"
        >
          <h2 class="text-dark-800 ml-2 font-bold text-3xl md:text-4xl">
            Pin Change Successful
          </h2>

          <img
            class="mx-auto py-6 sm:py-10"
            src="../../assets/icons/happy.svg"
            alt=""
          />

          <easiButton @click="goBack"> Back to login</easiButton>
        </easiContainer>
        <!-- ///// Success Message End ///// -->
      </div>
    </div>
    <easiModal
      v-if="display === 'otp'"
      :showCloseButton="false"
      :isBorder="false"
      :center="true"
      rounded="4xl"
    >
      <div style="padding: 32px">
        <p
          style="
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.2px;
            text-align: center;
          "
          class="text-grey mb-0"
        >
          OTP Verification
        </p>

        <span
          style="
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #8d8e8d;
          "
          class="mt-3 block"
        >
          Please input OTP sent to your email and <br />
          phone number
        </span>
      </div>
      <span class="mb-4 flex justify-around items-center">
        <easiOtp
          v-model="otpCode"
          size="10"
          :token_length="6"
          :max-length="1"
          justify="center"
          @input="checkOtp"
          :error="errorRules.otpCode"
        />
      </span>
      <div
        class="text-dark-800 text-left flex items-center justify-center"
        style="
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.25px;
        "
      >
        Did not receive OTP?
        <easiButton
          @click="resendOTP"
          variant="text"
          :loading="loading.btnOtp"
          color="secondary"
          class="text-sm -ml-4 px-0"
          type="button"
          ><span
            style="
              font-weight: 700;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.25px;
            "
            >Resend</span
          ></easiButton
        >
      </div>
    </easiModal>
    <easiAlert
      v-if="updateSuccess"
      @close="updateSuccess = false"
      noIcon
      :showDefaultTop="false"
    >
      <div class="px-6">
        <div class="flex justify-center pt-6">
          <span>
            <img src="@/assets/img/newSuccess.gif" class="h-32" alt="" />
          </span>
          <!-- <img class="w-32" :src="success" alt="" /> -->
        </div>
        <div class="w-10/12 mx-auto text-center font-bold text-lg mt-4">
          <h3 class="text-primary font-extrabold text-2xl mb-4">Success!</h3>

          <span class="mt-2 font-medium text-sm"
            >Passcode reset successful.</span
          >
        </div>

        <easiButton @click="$router.push('/login')" class="mt-6" block
          >Sign in</easiButton
        >
      </div>
    </easiAlert>
  </easiLayout>
</template>

<script setup>
import { ref, reactive, onMounted, watch } from "vue";
import { useDataStore } from "../../stores/data.js";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { helperFunctions } from "../../composable/helperFunctions";

const { processNumber } = helperFunctions;
const { mutate } = useDataStore();
const router = useRouter();
const toast = useToast();
const currentInput = ref(0);
const resentOtpLoading = ref(false);
const showOtpForm = ref(JSON.parse(window.localStorage.getItem("showOtpForm")));
const display = ref(window.localStorage.getItem("forgotPasswordForm"));
const disabled = ref(false);
watch(display, (val) => {
  window.localStorage.setItem("forgotPasswordForm", val);
});
let userInput = ref("");
const sentOtpLoading = ref(false);
const loading = ref(false);

const otpCode = ref("");
const args = reactive({
  email: "",
  phoneNumber: "",
  pin: "",
  confirmPin: "",
});
const showPin = ref(true);
const showPin1 = ref(true);
const updateSuccess = ref(false);

const asEmployee = ref(false);
const showTip = ref(false);

const errorRules = reactive({
  userInput: false,
  pin: false,
  otpCode: false,
  confirmPin: false,
});

watch(showOtpForm, (val) => {
  window.localStorage.setItem("showOtpForm", val);
});

onMounted(() => {
  display.value === null ? (display.value = "email") : display.value;

  ///Init the value to the display on load to show the right form///
  // showOtpForm.value === "null"
  //   ? (showOtpForm.value = false)
  //   : showOtpForm.value;
});

function validate() {
  if (userInput.value.trim() === "" && display.value === "email") {
    errorRules.userInput = "Please enter your email or phone number";
    return false;
  } else if (otpCode.value.trim() === "" && display.value === "otp") {
    errorRules.otpCode = "Please enter the otp code sent to your email";
    return false;
  } else if (args.pin.trim() === "") {
    errorRules.pin = "Please set a pin";
    return false;
  } else if (args.confirmPin.trim() === "") {
    errorRules.confirmPin = "Please confirm your pin";
    return false;
  } else if (args.pin !== args.confirmPin) {
    errorRules.pin = "Pin does not match. Please check and put a matching pin";
    return false;
  } else {
    Object.keys(errorRules).forEach((key) => {
      errorRules[key] = false;
    });
    return true;
  }
}

// Update OTP Input Functions
function checkPin(otp_input) {
  args.pin = otp_input;
}

function checkConfirmPin(otp_input) {
  args.confirmPin = otp_input;
}

function checkOtp(otp_input) {
  otpCode.value = otp_input;
  if (otpCode.value.length === 6) {
    handleOtp();
  }
}

function checkInputType(payload) {
  if (payload.includes("@")) {
    args.email = payload;
    return "email";
  } else {
    args.phoneNumber = payload;
    return "phone";
  }
}

async function checkPasscode() {
  if (validate()) {
    display.value = "otp";
  }
}
async function handleOtp() {
  if (otpCode.value.trim() === "" && display.value === "otp") {
    errorRules.otpCode = "Please enter the otp code sent to your email";
    return;
  }
  display.value = "passcode";
}

async function submit() {
  if (validate()) {
    try {
      console.log("Submit");
      loading.value = true;

      checkInputType(userInput.value); //check the input type set it to the appropraite args

      let payload = {
        pin: args.pin,
        code: otpCode.value,
        email: args.email.trim().toLocaleLowerCase(),
        phoneNumber: processNumber(args.phoneNumber),
        reference: window.localStorage.getItem("otpReference"),
        accountType: asEmployee.value ? "EMPLOYEE" : "COMPANYADMIN",
      };

      //Check if the userinput is email or phone and dynamically add the property
      // const inputType = checkInputType(userInput.value);
      // if (inputType === "email") {
      //   payload.email = userInput.value;
      // } else {
      //   payload.phoneNumber = userInput.value;
      // }

      let res = await mutate({
        endpoint: "VerifyOtpForogotPassword",
        data: { input: payload },
        service: "AUTH",
      });

      // log(res);
      if (res.success) {
        loading.value = false;
        // toast.success(res.message);
        display.value = "email";
        updateSuccess.value = true;
      } else {
        toast.error(res.message);
      }
    } catch (e) {
      // log(e);
      loading.value = false;
      e.message.toLowerCase() == "record not found"
        ? (errorRules.otpCode = "OTP doesn't match. Please try again")
        : null;
    } finally {
      loading.value = false;
    }
  }
}

async function sendOTP() {
  checkInputType(userInput.value);
  const payload = {
    email: args.email,
    phoneNumber: processNumber(args.phoneNumber),
    accountType: asEmployee.value ? "EMPLOYEE" : "COMPANYADMIN",
  };

  if (userInput.value.trim() !== "") {
    //Clear user input error state
    errorRules.userInput = false;
    try {
      sentOtpLoading.value = true;

      let res = await mutate({
        endpoint: "SendOtpForgotPassword",
        data: { input: payload },
        service: "AUTH",
      });

      // log(res);
      if (res.success) {
        window.localStorage.setItem("otpReference", res.data.reference);
        sentOtpLoading.value = false;
        display.value = "otp";
        showOtpForm.value = true;
      } else {
        display.value = "email";
        showOtpForm.value = false;
        errorRules.userInput = res.message;
      }
    } catch (e) {
      // log(e);
      sentOtpLoading.value = false;
      const message =
        e.message === "User Not Found!"
          ? "Doesn't identify registered user's email or phone"
          : e.message;

      errorRules.userInput = message;
    } finally {
      sentOtpLoading.value = false;
    }
  } else {
    errorRules.userInput = "Please enter your email or phone number";
  }
}

async function resendOTP() {
  checkInputType(userInput.value);
  const payload = {
    email: args.email,
    phoneNumber: processNumber(args.phoneNumber),
    accountType: asEmployee.value ? "EMPLOYEE" : "COMPANYADMIN",
  };

  log(processNumber(args.phoneNumber));
  if (userInput.value.trim() !== "") {
    //Clear user input error state
    errorRules.userInput = false;
    try {
      console.log("hmm");

      resentOtpLoading.value = true;

      let res = await mutate({
        endpoint: "SendOtpForgotPassword",
        data: { input: payload },
        service: "AUTH",
      });

      // log(res);
      if (res.success) {
        window.localStorage.setItem("otpReference", res.data.reference);
        toast.success(res.message);
      }
    } catch (e) {
      // log(e);
      errorRules.userInput = e.message;
    } finally {
      resentOtpLoading.value = false;
    }
  } else {
    errorRules.userInput = "Please enter your email or phone number";
  }
}

function close() {
  if (display.value === "email") {
    router.go(-1);
  }
  if (display.value === "passcode") {
    display.value = "email";
  } else if (display.value === "otp") {
    display.value = "passcode";
  } else {
    display.value = "email";
  }

  display.value = "email";
  showOtpForm.value = false;
}

function log(arg) {
  console.log(JSON.parse(JSON.stringify(arg)));
}
</script>

<style scoped>
.head-text {
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  color: #292a29;
}

.sub-head-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}
</style>
